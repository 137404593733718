export const MESSAGE_DIALOG_TYPE = {
  CONFIRM: 'confirm',
  ALERT: 'alert',
} as const;

export const MESSAGE_DIALOG_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const;

export const MESSAGE_DIALOG_VARIANT = {
  NORMAL: 'normal',
  CRITICAL: 'critical',
} as const;
