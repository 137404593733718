import * as React from 'react';
import type { MessageDialogCallFunction } from '../types';

interface MessageDialogContextValue {
  alert: MessageDialogCallFunction;
  confirm: MessageDialogCallFunction;
}

export const Context = React.createContext<MessageDialogContextValue | null>(
  null,
);

export const useMessageDialog = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error(
      'useMessageDialog must be used within a MessageDialogProvider',
    );
  }
  return context;
};
