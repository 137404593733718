import * as React from 'react';
import * as Dialog from '../../dialog';
import { MessageDialogContent } from './message-dialog-content';
import type {
  MessageDialogType,
  MessageDialogSizeType,
  MessageDialogVariantType,
} from '../types';

export interface Props {
  type: MessageDialogType;
  size?: MessageDialogSizeType;
  variant?: MessageDialogVariantType;
  open?: boolean;
  asChild?: boolean;
  onOpenChange?: (open: boolean) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export function MessageDialog({
  children,
  open,
  onOpenChange,
  ...contentProps
}: React.PropsWithChildren<Props>) {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <MessageDialogContent {...contentProps}>{children}</MessageDialogContent>
    </Dialog.Root>
  );
}

export default MessageDialog;
