import { HttpResponse } from 'msw';

export function getEmpUserMenuResolver() {
  return HttpResponse.json({
    data: {
      menuList: [
        {
          menuId: 25553,
          menuName: '메일',
          sortOrder: 2,
          url: '/gw/app/mail',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M4.6875 6.75L11.3596 11.5403C11.7449 11.8168 12.2551 11.8168 12.6404 11.5403L19.3125 6.75M5.25 19H18.75C19.9926 19 21 17.9553 21 16.6667V7.33333C21 6.04467 19.9926 5 18.75 5H5.25C4.00736 5 3 6.04467 3 7.33333V16.6667C3 17.9553 4.00736 19 5.25 19Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          appCode: 'dop-employee-mail',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25554,
          menuName: '전자결재',
          sortOrder: 3,
          url: '/gw/app/approval',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9.55718 21.5574H4.75717C3.43168 21.5574 2.35717 20.4828 2.35718 19.1574L2.35727 4.75741C2.35728 3.43193 3.43179 2.35742 4.75727 2.35742H15.5575C16.883 2.35742 17.9575 3.43194 17.9575 4.75742V9.55742M6.55755 7.15742H13.7576M6.55755 10.7574H13.7576M6.55755 14.3574H10.1576M13.1574 18.2484L18.2485 13.1573L21.6427 16.5514L16.5515 21.6426H13.1574V18.2484Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          appCode: 'dop-employee-approval',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25555,
          menuName: 'Works',
          sortOrder: 4,
          url: '/gw/app/works',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-works',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25556,
          menuName: '캘린더',
          sortOrder: 5,
          url: '/gw/app/calendar',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M7.14286 7.90176H16.7812M6.48512 2.4375V4.07698M17.3125 2.4375V4.07678M20.5 7.07678L20.5 18.5625C20.5 20.2194 19.1569 21.5625 17.5 21.5625H6.5C4.84315 21.5625 3.5 20.2194 3.5 18.5625V7.07678C3.5 5.41992 4.84315 4.07678 6.5 4.07678H17.5C19.1569 4.07678 20.5 5.41992 20.5 7.07678Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          appCode: 'dop-employee-calendar',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25557,
          menuName: '게시판',
          sortOrder: 6,
          url: '/gw/app/board',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9.55718 21.5574H4.75717C3.43168 21.5574 2.35717 20.4828 2.35718 19.1574L2.35727 4.75741C2.35728 3.43193 3.43179 2.35742 4.75727 2.35742H15.5575C16.883 2.35742 17.9575 3.43194 17.9575 4.75742V9.55742M6.55755 7.15742H13.7576M6.55755 10.7574H13.7576M6.55755 14.3574H10.1576M13.1574 18.2484L18.2485 13.1573L21.6427 16.5514L16.5515 21.6426H13.1574V18.2484Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          appCode: 'dop-employee-board',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25558,
          menuName: '보고',
          sortOrder: 7,
          url: '/gw/app/report',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-report',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25559,
          menuName: '주소록',
          sortOrder: 8,
          url: '/gw/app/contact',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-contact',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25560,
          menuName: '예약',
          sortOrder: 9,
          url: '/gw/app/asset',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-asset',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25561,
          menuName: '설문',
          sortOrder: 10,
          url: '/gw/app/survey',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-survey',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25562,
          menuName: '전사문서함',
          sortOrder: 11,
          url: '/gw/app/docfolder',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-approval-docs',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25563,
          menuName: '문서관리',
          sortOrder: 12,
          url: '/gw/app/docs',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-docs',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25564,
          menuName: '커뮤니티',
          sortOrder: 13,
          url: '/gw/app/community',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-community',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25565,
          menuName: 'ToDo+',
          sortOrder: 14,
          url: '/gw/app/todo',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-todo',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25566,
          menuName: '자료실',
          sortOrder: 15,
          url: '/gw/app/webfolder',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-webfolder',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25567,
          menuName: '인사',
          sortOrder: 16,
          url: '/manage/-',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-hr',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25568,
          menuName: '급여',
          sortOrder: 17,
          url: '/manage/-',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-payroll',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25569,
          menuName: '고용전자계약',
          sortOrder: 18,
          url: '/manage/-',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-econtract',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25570,
          menuName: '법정의무교육',
          sortOrder: 19,
          url: '/manage/-',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-legal-education',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25571,
          menuName: '경비',
          sortOrder: 20,
          url: '/manage/-',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-expenses',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25572,
          menuName: '차량운행일지',
          sortOrder: 21,
          url: '/manage/-',
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_1036_26634" fill="white"><rect x="3" y="3" width="8" height="8" rx="1"/></mask><rect x="3" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-1-inside-1_1036_26634)"/><mask id="path-2-inside-2_1036_26634" fill="white"><rect x="3" y="13" width="8" height="8" rx="1"/></mask><rect x="3" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-2-inside-2_1036_26634)"/><mask id="path-3-inside-3_1036_26634" fill="white"><rect x="13" y="3" width="8" height="8" rx="1"/></mask><rect x="13" y="3" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-3-inside-3_1036_26634)"/><mask id="path-4-inside-4_1036_26634" fill="white"><rect x="13" y="13" width="8" height="8" rx="1"/></mask><rect x="13" y="13" width="8" height="8" rx="1" stroke="currentColor" stroke-width="3.2" mask="url(#path-4-inside-4_1036_26634)"/></svg>',
          appCode: 'dop-employee-vehicle-log',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25573,
          menuName: '복지',
          sortOrder: 22,
          url: '/-/-',
          icon: '',
          appCode: 'dop-employee-welfare',
          disabled: false,
          useLock: false,
        },
        {
          menuId: 25574,
          menuName: '연동센터',
          sortOrder: 23,
          url: '/-/-',
          icon: '',
          appCode: 'dop-employee-integration',
          disabled: false,
          useLock: false,
        },
      ],
    },
  });
}
