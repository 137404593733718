import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/apps/dop-employee-portal/components/Providers/AppProvider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/app/apps/dop-employee-portal/mocks/index.ts");
;
import(/* webpackMode: "eager" */ "/tmp/app/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.82.0/node_modules/next/font/local/target.css?{\"path\":\"../../packages/fonts/nextjs.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./Pretendard-1.3.9/web/variable/woff2/PretendardVariable.woff2\",\"display\":\"swap\",\"weight\":\"45 920\",\"variable\":\"--font-pretendard-variable\"}],\"variableName\":\"PretendardVariable\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/dop-ui-root-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/next-app-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/shared/lib/i18n/client/translations-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslation"] */ "/tmp/app/packages/ui-react/shared/lib/i18n/client/use-translation.tsx");
